@import "styles/utils";
.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
  @include phone() {
    scroll-padding-top: 70px;
  }

  .footerSection{
    display: block;
    max-height:500px;
    width:100%;
    background:none;
  }
}
